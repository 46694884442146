
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      isAuthenticated: this.$auth0.isAuthenticated,
      user: this.$auth0.user,
      showUserMenu: false
    }
  },

  methods: {
    toggleUserMenu () {
      this.showUserMenu = !this.showUserMenu
    },

    logout () {
      this.$auth0.logout({ returnTo: window.location.origin });
    },
  }
})

