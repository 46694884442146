
import { createRouter as createVueRouter, createWebHistory, RouteRecordRaw, Router } from "vue-router";
import { createAuthGuard } from "@auth0/auth0-vue";
import { App } from 'vue';

export function createRouter(app: App): Router {

  const routes: Array<RouteRecordRaw> = [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/HomeView.vue'),
    },
    {
      path: '/support',
      name: 'support-container',
      component: () => import('@/containers/SupportContainer.vue'),
      children: [
        {
          path: '',
          name: 'support',
          component: () => import('@/views/SupportView.vue'),
        },
        {
          path: 'knowledge-base',
          name: 'knowledge-base',
          component: () => import('@/views/SupportKnowledgeBaseView.vue'),
        },
      ]
    }

  ]

  const router = createVueRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

  router.beforeEach(createAuthGuard(app))

  return router
}
