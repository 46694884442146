import { createApp } from 'vue'
import App from './App.vue'
import { createRouter } from "./router";
import store from './store'
import { createAuth0 } from '@auth0/auth0-vue';
import "bootstrap"
import VueFeather from 'vue-feather';
import { MsalPlugin } from './plugins/msal'
import BootstrapVue3 from 'bootstrap-vue-3'

const app = createApp(App)

app.use(
  createAuth0({
    domain: process.env.VUE_APP_AUTH0_DOMAIN || '',
    client_id: process.env.VUE_APP_AUTH0_CLIENT_ID || '',
    redirect_uri: window.location.origin
  })
)

app.use(MsalPlugin, {
  auth: {
    clientId: process.env.VUE_APP_AZURE_OIDC_CLIENT_ID,
    authority: process.env.VUE_APP_AZURE_OIDC_TENANT_ID,
    redirectUri: process.env.VUE_APP_AZURE_OIDC_REDIRECT_URI,
  }
})

app.component(VueFeather.name, VueFeather);
app.use(BootstrapVue3)
app.use(store)
app.use(createRouter(app))
app.mount('#app')
