import { App } from 'vue';
import { LogLevel, PublicClientApplication, Configuration } from "@azure/msal-browser";

const MSAL_TOKEN = '$msal'

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    [MSAL_TOKEN]: PublicClientApplication
  }
}

export const MsalPlugin = {
  install(app: App, options: Configuration) {
    options.cache = {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    }

    options.system = {	
        loggerOptions: {
            /* eslint-disable-next-line */
            loggerCallback: (level: any, message: any, containsPii: any) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;		
                }	
            }	
        }	
    }
    
    const msal = new PublicClientApplication(options)
    app.config.globalProperties[MSAL_TOKEN] = msal
  }
}